
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "ups-shipping-rates",
  components: {},
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const isDataReady = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Shipping Rates", ["UPS"]);
    });
    console.log("store");
    store
      .dispatch(Actions.GET_UPS_SHIPPING_RATES)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving ups rate.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    return {
      currentUser,
      isDataReady,
    };
  },
});
